import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

import { style } from './style.js';

import './secondary-menu/secondary-menu.js'
import './preamble/preamble-menu.js'

@customElement('app-footer')
export class AppFooter extends LitElement {

  static styles = [
    style
  ]

    render() {
        return html`
<footer>

<secondary-menu></secondary-menu>
<preamble-menu></preamble-menu>
<section>
  <p>All Rights Reserved - <a href="https://example.com" target="_blank">Online Presence</a></p>
</section>

</footer>

    `;}
}