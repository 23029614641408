import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { styles } from './style.js';

@customElement('app-header')
export class AppHeader extends LitElement {

  static styles = [
    styles
  ]
  @property()
  menuItems: Array<any>;
  constructor() {
      super();
      this.menuItems = [
          {
              title: 'Home',
              link: '/',
              dropdown: false,
          },
          {
            title: 'About Us',
            link: '/about-us',
            dropdown: false,
        },
          {
            title: 'Our Services',
            link: '/services',
            dropdown: false,
        },
        {
          title: 'Get in Touch',
          link: '/contact-us',
          dropdown: false,
      },
      ];
  }
  render() {
    return html`
<header>

<menu>
<a id="logo" href="/">
<img src="public/assets/branding/clx-GEMS2.svg"/>
</a>
<span>
<ul>
<a href="/contact"><h1>Connect with Us</h1></a>
<li>
  <a href="#">780-940-7369</a>
</li>
<li>
  <a href="#">administrator@thegemsfamily.org</a>
</li>
</ul>

<ol>
<li>
<a href="#"><img src="public/assets/media/icon-linkedin.svg" /></a>
</li>
<li>
<a href="#"><img src="public/assets/media/icon-facebook.svg" /></a>
</li>
</ol>
</span>

</menu>

<nav class="menu">
      ${this.menuItems.map((item) => {
          return html`
          <li class="menu-item ${item.dropdown ? 'has-dropdown' : ''}">
            <a href="${item.link}">${item.title}</a>
          </li>
        `;
      })}
</nav>
</header>
    `;}

}
