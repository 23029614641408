import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

import '../../ponents/header/app-header.js';
import '../../ponents/footer/app-footer.js';

import { styles } from './style.js';


@customElement('app-home')
export class AppHome extends LitElement {

  static styles = [
    styles
  ]

  render() {
    return html`
<app-header></app-header>
<main>

<div>
<h1>"Providing a family-oriented home, for every child to thrive."</h1>
<p>The Gems Family is an Alberta organization dedicated to providing positive life experiences and specialized care solutions to improve the individual development of children and youths experiencing trauma.</p>
<a href="about-us"><button>Learn More</button></a>
</div>

</main>



<article>
<a href="projects/ipv"><h1>Our Services</h1></a>

<ul>
<li>
  <a href="/resources/videos"><div>
  <img src="public/assets/icons/icon-home.svg" />
  <h2>Furnished Homes</h2>
  </div></a>
</li>

<li>
  <a href="/resources/podcasts"><div class="card">
  <img src="public/assets/icons/icon-heal.svg" />
  <h2>Spiritual Counseling</h2>
  </div>
  </a>
</li>

<li>
  <a href="/resources/presentations"><div class="card">
  <img src="public/assets/icons/icon-improve.svg" />
  <h2>Life Skills & Strategies</h2>
  </div>
  </a>
</li>

<li>
  <a href="/resources/reports"><div class="card">
  <img src="public/assets/icons/icon-family.svg" />
  <h2>Family Wellness</h2>
  </div>
  </a>
</li>
</ul>

</article>

<section>
<div>
<a href="#"><h1>A family-oriented home, for every child to thrive.</h1></a>
<a href="/contact-us"><h2>Contact us today to learn more about our services and our committment to our clients.</h2></a>
</div>
</section>
<app-footer></app-footer>
    `;}
}