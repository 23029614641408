import { css } from 'lit';
export const styles = css`
main {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  
  background-image: url("../../../../public/assets/pix/stock-hero.jpg");
  background-size: 100%;
  background-origin: border-box;
  background-repeat: no-repeat;

  background-position: top;

  width: clamp(300px, 100vw, 100%);
  height: 100vh;
}
main div {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  background-color: #01759375;
}
main h1 {
  font-size: clamp(20px, 4vw, 48px);
  color: #F34213;
  text-align: center;

  width: 50%;
  padding: 1%;
  margin: 0;
}
main p {
    font-size: clamp(16px, 2vw, 24px);
    color: #fff;
    text-align: center;

    width: 25%;
    padding: 2%;
    margin: 0;
}
main a {
  margin: 1%;
}
main a button {
  padding: 1rem 1rem;
  border: none;
  background-color: #017593;
  border-radius: 16px;

  font-size: 18px;
  color: #fff;

  cursor: pointer;
}

article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #01759350;
  width: 100%;
}
article a {
  text-decoration: none;
  cursor: pointer;
  width: 100%;
}
article h1 {
  color: #017593;
  font-size: 64px;
  font-weight: 600;

  margin: 0;
  padding: 1%;

  text-align: center;
  transition: color 0.2s ease-in-out;
}
article ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  list-style: none;
  padding: 0;

}
article ul li div {
  width: 16rem;
  border: 6px solid #017593;
  border-radius: 2rem;
  overflow: hidden;

  background-image: url("../../../../public/assets/pix/stock-home.jpg");
  background-size: 100%;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-attachment: fixed;

  cursor: pointer;
  padding: 40% 20%;
}
article ul li div h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 600;

  text-align: start;
}
article ul li div img {
  width: 100%;
  height: 100%;

  transition: transform .5s ease-in;
}
article ul li div:hover {
  background-color: #243E9025;
}
article ul li div:hover h2 {
  color: #c0dd00;
  transition: color .2s ease-in;
}
article ul li div:hover img {
  transform: scale(0.95);
}
article ul li div a {
  text-decoration: none;
  cursor: pointer;
}

section {
  display: flex;
  flex-flow: column wrap;
  justify-content: end;
  align-items: center;

  background-image: url("../../../../public/assets/pix/stock-home2.jpg");
  background-size: 100%;
  background-position: center top 45%;
  background-origin: border-box;
  background-repeat: no-repeat;

  width: 100%;
  height: 50vh;
}
section a {
text-decoration: none;
}
section h1 {
  font-size: 48px;
  color: #99C71D;
  text-align: center;

  margin: 0;
}
section h2 {
  font-size: 24px;
  color: #fff;
  text-align: center;

  margin: 0;
  transition: color .2s ease-in;
}
section div {
  justify-content: center;
  background-color: #017593;

  width: 100%;
  padding: 1%;
}
`;