import { css } from "lit";
export const menuStyle2 = css`
.menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 0;
    list-style: none;
}

.menu-item {
    position: relative;
}
.menu-item>a {
    color: #017593;
    font-size: 18px;
    font-weight: 600;

    position: relative;
    padding: 1rem;
    margin: .25rem;
    transition: color 0.2s ease;

    text-decoration: none;
}
.menu-item>a:hover {
    color: #C0DD00;
    transition: color 0.2s ease;
}
.menu-item>a:active {
    color: #f7f7f7;
    transition: color 0.3s ease;
}
`