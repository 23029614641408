import { css } from 'lit';

export const style = css`
footer {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  bottom: 0;
  width: clamp(200px, 100vw, 100%);

  border: none;
  padding-top: 1%;
  background-color: #01759350;
}
section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

section a {
  color: #000;
  font-size: 12px;
  text-decoration: none;
}

section a:hover {
  color: #ffae00;
}

section p {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}
`;